body {
  margin: 0;
  font-size: 0.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Polaris-Icon {
  margin: 0;
}

.Polaris-DatePicker__Header {
  position: unset;
}
.Polaris-DatePicker__MonthLayout {
  display: flex;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.disable {
  pointer-events: none;
  opacity: 0.9;
}
.ml-4 {
  margin-left: 0.25rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.ml-16 {
  margin-left: 1rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-16 {
  margin-right: 1rem;
}
.mr-12 {
  margin-right: 0.75rem;
}
.pd-16 {
  padding: 1rem;
}
.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 1rem;
}
.pd-32 {
  padding: 2rem;
}
.pb-4 {
  padding-bottom: 4px;
}
.pb-8 {
  padding-bottom: 8px;
}
.gap-4 {
  gap: 0.25rem;
}
.gap-8 {
  gap: 0.5rem;
}
.gap-16 {
  gap: 1rem;
}
.space-vertical-8 {
  margin: 0.5rem 0;
}
select {
  padding-left: 1rem;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid var(--p-color-border);
}
.no-border-bottom {
  border-bottom: none !important;
}
.d-inline-block {
  display: inline-block;
}
.Polaris-Frame__NavigationDismiss {
  display: none;
}
.Polaris-TextField input {
  font-size: 13px;
}
.Polaris-Select .Polaris-Select__Content {
  font-size: 13px;
}
.Polaris-TextField__CharacterCount {
  font-size: 13px;
}
.Polaris-Tabs__ButtonWrapper .Polaris-Tabs {
  width: 100%;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge:has(#order-tracking-modal-title) {
  width: 95vw;
  max-width: unset;
}

@media (max-width: 768px) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge:has(#order-tracking-modal-title) {
    width: 100vw;
    max-width: unset;
  }
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge:has(#order-tracking-modal-title) {
  h2 {
    width: 100%;
  }
}

.Polaris-Labelled__LabelWrapper label.Polaris-Label__Text {
  font-size: 0.8125rem;
}

.bold-text-styled {
  .Polaris-Text--root {
    font-size: 13px;
  }
}
